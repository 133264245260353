import React, { useEffect, useState, Component } from 'react'
import { Link, useNavigate, Navigate } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import FormValidator from '../FormValidator';
import { withRouter } from '../withRouter';

import { useParams } from 'react-router-dom';

class VerifyOTPMobile extends Component {

  constructor(props) {

    super(props);

     console.log("parameter=",props);
    
    this.validator = new FormValidator([
      {
        field: 'otp',
        method: 'isEmpty',
        validWhen: false,
        message: 'The OTP field is required.'
      }
    ]);
    this.state = {
      phone: localStorage.getItem("phone"),
      otp: '',
      data: [],
      validation: this.validator.valid(),

    }

    this.submitted = false;
  }
  handleChange = event => {
    event.preventDefault();
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleFormSubmit = async event => {
    event.preventDefault();

    const validation = this.validator.validate(this.state);
    this.setState({
      validation
    });
    this.submitted = true;
    if (validation.isValid) {
      //reaches here if form validates successfully... 
      let phone = this.state.phone;
      let otp = this.state.otp;
      let result = await fetch(window.apiFetchBaseURL+'auth/otp-verify-mobile', {
        method: 'post',
        body: JSON.stringify({
          phone: phone,
          otp: otp,
        }),
        headers: {
          "access-control-allow-origin": "*",
          'Content-Type': 'application/json'
        }
      });

      result = await result.json();
   
      if (result.status == false) {      
        alert(result.message);
      }else if (result.status == true) {      
        
        //localStorage.setItem('adminUser', result.token);
        localStorage.setItem("authenticated", true);
        this.props.navigate('/');

      } else {
        alert("Something went wrong");
      }
    }
  }

  handleFormReSubmit = async event => {
    event.preventDefault();

    this.submitted = true; 
      let phone = this.state.phone;    
      let result = await fetch(window.apiFetchBaseURL+'auth/otp-resend', {
        method: 'post',
        body: JSON.stringify({
          phone: phone,
        }),
        headers: {
          "access-control-allow-origin": "*",
          'Content-Type': 'application/json'
        }
      });

      result = await result.json();
   
      if (result.status == false) {      
        alert(result.message);
      }else if (result.status == true) {      
        alert("We resent OTP code to your phone please check!")      

      } else {
        alert("Something went wrong");
      }
    
  }

  render() {
 
    let validation = this.submitted ? this.validator.validate(this.state) : this.state.validation;
    return (

      <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md={5}>
              <CCardGroup style={{ width: '100%' }} >
                <CCard className="p-4">
                  <CCardBody>
                    <CForm>
                      <h6>We sent OTP code to your phone:{this.state.phone}</h6>
                    
                      <CInputGroup className="mb-3">
                        <CInputGroupText>
                          <CIcon icon={cilUser} />
                        </CInputGroupText>
                        <CFormInput placeholder="OTP" type="text" autoComplete="otp" name="otp" onChange={this.handleChange} value={this.state.otp} />
                      </CInputGroup>
                      {validation.otp.message && <p className="ErrorColor">{validation.otp.message}</p>}
                      <CRow>
                        <CCol xs={6}>
                           <CButton color="secondary" className="px-4" onClick={this.handleFormReSubmit}>
                            RESEND OTP
                          </CButton>
                          
                        </CCol>
                        <CCol xs={6} className="text-right">
                         <CButton color="primary" className="px-4" onClick={this.handleFormSubmit}>
                            VERIFY OTP
                          </CButton>
                        </CCol>
                      </CRow>
                    </CForm>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    )
  }
}

export default withRouter(VerifyOTPMobile)
