import React from "react";
import {Navigate,Outlet} from 'react-router-dom'

const PublicComponent = ()=>{

    //const auth = sessionStorage.getItem('user');
    const auth = localStorage.getItem('adminUser');
    const checkauthunticate = localStorage.getItem('authenticated');
    return auth && checkauthunticate?<Navigate to="/" />:<Outlet />
}

export default PublicComponent;