import React from "react";
import {Navigate,Outlet} from 'react-router-dom'

const privateComponent = ()=>{

    //const auth = sessionStorage.getItem('user');
    const auth = localStorage.getItem('adminUser');
    const checkauthunticate = localStorage.getItem('authenticated');
    return auth && checkauthunticate?<Outlet />:<Navigate to="/login" />
}

export default privateComponent;